export const MenuItems =[
    {
        title: 'projects',
        url: '#projects',
        cName: 'nav-links',
    },
    {
        title: 'skills',
        url: '#',
        cName: 'nav-links',
    },
    {
        title: 'leadership',
        url: '#',
        cName: 'nav-links',
    },
    {
        title: 'contact',
        url: '#',
        cName: 'nav-links',
    },
    // {
    //     title: 'resume',
    //     url: '#',
    //     cName: 'nav-links',
    //     // cName: 'nav-links-mobile',
    // }
]