import React, {useEffect} from 'react'
import "./Home.css";
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import {FaGithub } from 'react-icons/fa';
import is_image1 from "../../assets/instructor-scheduler-poster.png";

export default function InstructorScheduler() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
      };
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  
    const projectTags = "Node.js,MongoDB,Express,Axios,Mongoose"
    const tags = projectTags.split(",");
    const renderTags = tags.map((tag) => (
        <div className="projects-tag">{tag}</div>
    ));
  
  
    return (
      <div style={{paddingBottom: "5%"}}className='format3'>
        
              <div className='navigateHomeButton'>
              <Button 
                  color1= {"#FFFFFF"}
                  children = {"go back home"}
                  onClick={handleClick}
                  size={"500"}
                  fontSize= {"1.3rem"}
                  ></Button>
              </div>
          <h1 className='subheading2'>Instructor Scheduler</h1>
          <div className="projectsPageTags">
          {renderTags}
          </div>
          <div className='github-link'>
          <FaGithub className="icon-contact"></FaGithub>
                <a className="subheading3" href="https://github.com/palak-bhargava/InstructorScheduler" target="_blank">
                      Github Repo
                      </a>
          </div>
            
  
          
  
          <div className='format4'>
          
          <h1 className='subheading2' style={{textAlign: "left"}}>Project Overview</h1>
          <h1 className='body1' style={{textAlign: "left"}}>
          Instructor Scheduler is a web application made to reduce the administrative burden of manually creating a schedule that aligns with each instructor. The project takes into account the instructor's preferred teaching times, preferred courses/course sections, and the availability of the courses in conjunction with an AI scheduling alogrithm to produce the most optimal schedule for each instructor for the upcoming semester. 
          </h1>
          <h1 className='subheading2' style={{textAlign: "left"}}>My Contributions</h1>
          <h1 className='body1' style={{textAlign: "left"}}>
          Having honed my skills in Frontend Development, I wanted to challenge myself for this project and  and took up an exclusively Backend role.

            <ul>
                <li> <b>Project Setup: </b>To kickstart the project, I configured the Vue client and the Node.js server and tested both components ran successfully. I also fine-tuned the package.json and package-lock.json files on the server and the client by installing basic dependencies, importing fonts, and setting up anything else needed.</li>
                <li> <b>RESTful API and Database: </b>I was responsible for creating schemas, implementing RESTful API methods, and managing the MongoDB database. Effective communication with the Frontend team was important as I collaborated closely with them to comprehend the required RESTful API methods, emphasizing aspects such as search and filtering by specific parameters. Rigorous testing of the API's functionality using Postman was an integral part of this process.</li>
                <li> <b>Scheduling Algorithm: </b>My primary contribution was the design and implementation of the AI scheduling algorithm. While there were numerous examples available online, the project demanded a highly specific algorithm tailored to the unique requirements outlined by our Faculty Advisor. Opting to create a custom algorithm from scratch, I incorporated various factors to formulate a schedule that perfectly aligned with the project's needs.</li>
            </ul>
          
          </h1>
          <img src={is_image1} className='poster-image'></img>
  
  
  
          </div>
         
        </div>
    )
}
